// list of group names
export const group_names = {
  owner: { value: 'owner', name: 'Owner' },
  admin: { value: 'administrator', name: 'Administrator' },
  vendor: { value: 'vendor', name: 'Vendor' },
  buyer: { value: 'buyer', name: 'Buyer' },
  estimator: { value: 'estimator', name: 'Estimator' },
  superintendent: { value: 'superintendent', name: 'Superintendent' },
  laborer: { value: 'laborer', name: 'Laborer' },
};

// personas
export default {
  default: {
    value: 'default',
    layout: 'LayoutDefault',
    group_names: [{ value: 'owner', name: 'Owner' }],
  },
  buyer: {
    value: 'buyer',
    homePage: { name: 'BuyerDashboard' },
    layout: 'LayoutBuyer',
    // roles and privileges
    group_names: [
      { value: 'owner', name: 'Owner' },
      { value: 'administrator', name: 'Administrator' },
      { value: 'estimator', name: 'Estimator' },
      { value: 'buyer', name: 'Buyer' },
      { value: 'superintendent', name: 'Superintendent' },
      { value: 'laborer', name: 'Laborer' },
    ],
  },
  vendor: {
    value: 'vendor',
    homePage: { name: 'VendorDashboard' },
    layout: 'LayoutVendor',
    // roles and privileges
    group_names: [
      { value: 'owner', name: 'Owner' },
      { value: 'administrator', name: 'Administrator' },
      { value: 'vendor', name: 'Vendor' },
    ],
  },
  super: {
    value: 'super',
    layout: 'LayoutSuperadmin',
    homePage: { name: 'SuperadminEmails' },
    // roles and privileges
    group_names: false,
  },
  // default: 'LayoutDefault',
  // buyer: 'LayoutBuyer',
  // buyeradmin: 'LayoutBuyerAdmin',
  // vendor: 'LayoutVendor',
};
