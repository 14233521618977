import Vue from 'vue';
import VueRouter from 'vue-router';
import Page404 from '../views/Errors/404';
import RouteWrapper from '../components/Partial/RouteWrapper';
import AuthorizationGate from './AuthorizationGate';
import OrderConstants from '@/shared/constants/OrderConstants';
import PersonaConstants from '@/shared/constants/PersonaConstants';
import { group_names } from '@/shared/constants/PersonaConstants';
import store from '../store';

const BUYER = PersonaConstants.buyer.value;
const VENDOR = PersonaConstants.vendor.value;
const SUPER = PersonaConstants.super.value;
const ALL_PERSONA = [BUYER, VENDOR, SUPER];

const R_OWNER = group_names.owner.name;
const R_ADMIN = group_names.admin.name;
const R_VENDOR = group_names.vendor.name;
const R_BUYER = group_names.buyer.name;
const R_ESTIMATOR = group_names.estimator.name;
const R_SUPERINTENDENT = group_names.superintendent.name;
const R_LABORER = group_names.laborer.name;
const R_EMPTY = null;

const ALL_ROLES = [
  R_OWNER,
  R_ADMIN,
  R_VENDOR,
  R_BUYER,
  R_ESTIMATOR,
  R_SUPERINTENDENT,
  R_LABORER,
];

const ALL_ROLES_WITH_EMPTY = [...ALL_ROLES, R_EMPTY];

Vue.use(VueRouter);
const authenticatedRoutes = [
  // analytics
  {
    path: '/analytics',
    name: 'Analytics',
    component: () =>
      import('@/views/Authenticated/Analytics/AnalyticsManager.vue'),
    meta: { permissions: { personas: ALL_PERSONA } },
  },
  // Dashboard
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import('@/views/Authenticated/Dashboard/DashboardManager.vue'),
    meta: { permissions: { personas: ALL_PERSONA } },
  },
  // Categories
  {
    path: '/category',
    name: 'CategoryList',
    component: () => import('@/views/Authenticated/Category/CategoryList'),
    meta: { permissions: { personas: [SUPER] } },
  },
  // Products
  {
    path: '/product',
    component: RouteWrapper,
    children: [
      {
        path: 'add',
        name: 'ProductAdd',
        component: () =>
          import('@/views/Authenticated/Product/Add/ProductAddManager'),
        meta: {
          permissions: { personas: [VENDOR, SUPER], roles: [R_OWNER, R_ADMIN, R_VENDOR] },
        },
      },
      {
        path: ':id/edit',
        name: 'ProductEdit',
        component: () =>
          import('@/views/Authenticated/Product/Edit/ProductEditManager'),
        meta: {
          permissions: { personas: [VENDOR, SUPER], roles: [R_OWNER, R_ADMIN, R_VENDOR] },
        },
      },
      {
        path: '',
        name: 'ProductList',
        component: () =>
          import('@/views/Authenticated/Product/List/ProductListManager'),
        meta: { permissions: { personas: ALL_PERSONA, roles: ALL_ROLES } },
      },
      {
        path: ':id',
        name: 'ProductView',
        component: () =>
          import('@/views/Authenticated/Product/View/ProductViewManager'),
        meta: { permissions: { personas: ALL_PERSONA, roles: ALL_ROLES } },
      },
    ],
  },
  // Orders
  {
    path: '/order',
    component: RouteWrapper,
    children: [
      // checkout
      {
        path: 'checkout',
        name: 'OrderCheckout',
        component: () =>
          import('@/views/Authenticated/Order/Add/OrderCheckout'),
        meta: { permissions: { personas: [BUYER, SUPER], roles: ALL_ROLES } },
      },
      {
        path: 'waiting',
        component: RouteWrapper,
        children: [
          {
            path: '',
            component: () =>
              import(
                '@/views/Authenticated/Order/Admin/Approval/List/OrderAdminListManager.vue'
              ),
            name: 'OrderWaitingList',
            meta: {
              permissions: {
                personas: [BUYER, SUPER],
                roles: [R_OWNER, R_ADMIN],
              },
            },
          },
        ],
      },
      // Non Admin Order Routes
      {
        path: '',
        name: 'OrderList',
        beforeEnter: (to, from, next) => {
          const isVendor = store.state.auth.user.persona === 'vendor';

          if (isVendor) {
            next({ name: 'OrderApproveList' });
          } else {
            next({ name: 'OrderRequestList' });
          }
        },
      },
      {
        path: 'request',
        name: 'OrderRequestList',
        component: () =>
          import('@/views/Authenticated/Order/List/OrderListManager.vue'),
        meta: {
          title: 'PENDING RFQs',
          status: OrderConstants.STATUS.REQUEST_PENDING.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'approved',
        name: 'OrderApproveList',
        component: () =>
          import('@/views/Authenticated/Order/List/OrderListManager.vue'),
        meta: {
          title: 'Approved RFQs',
          status: OrderConstants.STATUS.REQUEST_APPROVED.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'quoted',
        name: 'OrderQuotedList',
        component: () =>
          import('@/views/Authenticated/Order/List/OrderListManager.vue'),
        meta: {
          title: 'Pending Orders',
          status: OrderConstants.STATUS.QUOTE_SENT.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'accepted',
        name: 'OrderAcceptedList',
        component: () =>
          import('@/views/Authenticated/Order/List/OrderListManager.vue'),
        meta: {
          title: 'Accepted Orders',
          status: OrderConstants.STATUS.QUOTE_APPROVED.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'completed',
        name: 'OrderCompletedList',
        component: () =>
          import('@/views/Authenticated/Order/List/OrderListManager.vue'),
        meta: {
          title: 'Completed Orders',
          status: OrderConstants.STATUS.COMPLETED.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'rejected',
        name: 'OrderRejectList',
        component: () =>
          import('@/views/Authenticated/Order/List/OrderListManager.vue'),
        meta: {
          title: 'Rejected RFQs',
          status: OrderConstants.STATUS.REQUEST_DECLINED.value,
          permissions: {
            personas: [BUYER, SUPER],
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: ':id/edit',
        name: 'OrderEdit',
        component: () =>
          import('@/views/Authenticated/Order/Add/OrderCheckout'),
        meta: {
          permissions: {
            personas: [BUYER, SUPER],
            roles: [R_ADMIN, R_BUYER, R_ESTIMATOR],
          },
        },
      },
      {
        path: ':id',
        name: 'OrderView',
        component: () =>
          import('@/views/Authenticated/Order/View/OrderViewManager.vue'),
        meta: {
          permissions: { personas: ALL_PERSONA, roles: ALL_ROLES },
        },
      },
    ],
  },
  // Quotes
  {
    path: '/quote',
    component: RouteWrapper,
    children: [
      {
        path: 'request/:id',
        name: 'QuoteRequestList',
        component: () =>
          import(
            '@/views/Authenticated/Quote/List/QuoteRequestListManager.vue'
          ),
        meta: {
          status: OrderConstants.STATUS.REQUEST_APPROVED.value,
          permissions: {
            personas: [VENDOR, BUYER, SUPER],
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'accepted/:id',
        name: 'QuoteAcceptedList',
        component: () =>
          import('@/views/Authenticated/Quote/List/QuoteListManager.vue'),
        meta: {
          status: OrderConstants.STATUS.QUOTE_APPROVED.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'completed',
        name: 'QuoteCompletedList',
        component: () =>
          import('@/views/Authenticated/Quote/List/QuoteListManager.vue'),
        meta: {
          status: OrderConstants.STATUS.COMPLETED.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: 'rejected',
        name: 'QuoteRejectedList',
        component: () =>
          import('@/views/Authenticated/Quote/List/QuoteListManager.vue'),
        meta: {
          status: OrderConstants.STATUS.QUOTE_REJECTED.value,
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
      {
        path: ':id',
        name: 'QuoteView',
        component: () =>
          import('@/views/Authenticated/Quote/View/QuoteViewManager.vue'),
        meta: {
          permissions: {
            personas: ALL_PERSONA,
            roles: ALL_ROLES,
          },
        },
      },
    ],
  },
  // Company/Members
  {
    path: '/company',
    component: RouteWrapper,
    children: [
      {
        path: 'member',
        component: RouteWrapper,
        children: [
          {
            path: 'add',
            name: 'MemberAdd',
            component: () =>
              import(
                '@/views/Authenticated/Company/Member/Add/MemberAddManager'
              ),
            meta: {
              permissions: { personas: ALL_PERSONA, roles: [R_OWNER, R_ADMIN] },
            },
          },
          {
            path: '',
            name: 'MemberList',
            component: () =>
              import(
                '@/views/Authenticated/Company/Member/List/MemberListManager'
              ),
            meta: {
              permissions: { personas: ALL_PERSONA, roles: [R_OWNER, R_ADMIN] },
            },
          },
          {
            path: ':id',
            name: 'MemberView',
            component: () =>
              import(
                '@/views/Authenticated/Company/Member/View/MemberViewManager'
              ),
            meta: {
              permissions: { personas: ALL_PERSONA, roles: [R_OWNER, R_ADMIN] },
            },
          },
        ],
      },
      {
        path: '',
        redirect: { name: 'VendorList' },
      },

      // Vendor Company List
      {
        path: 'vendor',
        name: 'VendorList',
        component: () =>
          import('@/views/Authenticated/Company/List/VendorList'),
        meta: {
          permissions: { personas: [SUPER], roles: ALL_ROLES },
        },
      },
      // Buyer Company List
      {
        path: 'buyer',
        name: 'BuyerList',
        component: () => import('@/views/Authenticated/Company/List/BuyerList'),
        meta: {
          permissions: { personas: [SUPER], roles: ALL_ROLES },
        },
      },
      {
        path: 'add',
        name: 'CompanyAdd',
        component: () => import('@/views/Authenticated/Company/CompanyAdd'),
        meta: {
          permissions: { personas: [SUPER], roles: ALL_ROLES },
        },
      },
      {
        path: ':id',
        name: 'CompanyView',
        component: () => import('@/views/Authenticated/Company/CompanyView'),
        meta: {
          permissions: { personas: [SUPER], roles: ALL_ROLES },
        },
      },
    ],
  },
  //Account Settings
  {
    path: '/account',
    name: 'AccountSettings',
    component: () => import('@/views/Authenticated/AccountSettings/NonAdmin'),
    meta: {
      permissions: { personas: ALL_PERSONA, roles: ALL_ROLES_WITH_EMPTY },
    },
  },
  {
    path: '/account/:id',
    name: 'AccountSettingsOther',
    component: () => import('@/views/Authenticated/AccountSettings/NonAdmin'),
    meta: {
      permissions: { personas: ALL_PERSONA, roles: ALL_ROLES_WITH_EMPTY },
    },
  },
  {
    path: '/admin-account',
    name: 'AdminAccountSettings',
    component: () => import('@/views/Authenticated/AccountSettings/Admin'),
    meta: { permissions: { personas: ALL_PERSONA, roles: ALL_ROLES } },
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () =>
      import('@/views/Authenticated/AccountSettings/ChangePassword'),
    meta: {
      permissions: { personas: ALL_PERSONA, roles: ALL_ROLES_WITH_EMPTY },
    },
  },
  {
    path: '/change-password/:id',
    name: 'ChangePasswordOther',
    component: () =>
      import('@/views/Authenticated/AccountSettings/ChangePassword'),
    meta: {
      permissions: { personas: ALL_PERSONA, roles: ALL_ROLES_WITH_EMPTY },
    },
  },
  {
    path: '/change-email',
    name: 'ChangeEmail',
    component: () =>
      import('@/views/Authenticated/AccountSettings/ChangeEmail'),
    meta: { permissions: { personas: ALL_PERSONA, roles: ALL_ROLES } },
  },
  // Emails (temporary page)
  {
    path: '/emails',
    name: 'Emails',
    component: () => import('@/views/Authenticated/Emails'),
    meta: {
      permissions: { personas: [SUPER], roles: ALL_ROLES },
    },
  },
  // Notications
  {
    path: '/notification',
    name: 'NotificationList',
    component: () =>
      import('@/views/Authenticated/Notification/List/NotificationList.vue'),
    meta: {
      permissions: { personas: ALL_PERSONA, roles: ALL_ROLES },
    },
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () =>
        import('@/views/Authenticated/Favorites/FavoriteList'),
    meta: {
      permissions: {
        personas: [BUYER, SUPER],
        roles: ALL_ROLES,
      },
    },
  },
  {
    path: '/subscription',
    name: 'Subscriptions',
    component: () =>
      import(
        '@/views/Authenticated/AccountSettings/Subscription.vue'
      ),
    meta: {
      permissions: { personas: [VENDOR, SUPER], roles: [R_OWNER, R_ADMIN] },
    },
  },
  {
    path: '/subscription-checkout/:id',
    name: 'SubscriptionCheckout',
    component: () =>
      import(
        '@/views/Authenticated/AccountSettings/SubscriptionCheckout.vue'
      ),
    meta: {
      permissions: { personas: [VENDOR, SUPER], roles: [R_OWNER, R_ADMIN] },
    },
  },
];

/** PUBLIC ROUTES */
const publicRoutes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/Public/NewLanding.vue'),
  },
  {
    path: '/old-home',
    name: 'Old Home',
    component: () => import('@/views/Public/Landing.vue'),
  },
  {
    path: '/wait-list',
    name: 'Waiting List',
    component: () => import('@/views/Public/Landing.vue'),
  },
  {
    path: '/vendor',
    name: 'Vendor',
    component: () => import('@/views/Public/NewVendor.vue'),
  },
  {
    path: '/buyer',
    name: 'Buyer',
    component: () => import('@/views/Public/NewBuyer.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Public/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Public/Register.vue'),
  },
  {
    path: '/register-success',
    name: 'RegisterSuccess',
    component: () => import('@/views/Public/RegisterSuccess.vue'),
  },
  {
    path: '/password-reset',
    name: 'ResetPassword',
    component: () => import('@/views/Public/ResetPassword.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('@/views/Public/TermsAndConditions.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/Public/PrivacyPolicy.vue'),
  },
];

/** ERROR ROUTES */
const errors = [{ path: '*', name: 'Error404', component: Page404 }];
// const test = [{ path: '/test', component: Sandbox }];

const routes = [...authenticatedRoutes, ...publicRoutes, ...errors];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    setTimeout(() => {
      document.getElementById('app').scrollTo(0, 0)
      
    }, 300);
  }
});

router.beforeEach(AuthorizationGate);

export default router;
