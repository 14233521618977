var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : 'button',_vm._g({tag:"component",staticClass:"app-btn btn text-decoration-none",class:[
    _vm.text
      ? ("btn-text-" + _vm.color)
      : _vm.outline
      ? ("bg-white btn-outline-" + _vm.color + (_vm.disabled || _vm.loading ? '-disabled' : ''))
      : ("btn-" + _vm.color + (_vm.disabled || _vm.loading ? '-disabled' : '')),
    {
      'btn-sm': _vm.sm,
      'btn-xl': _vm.xl,
    } ],attrs:{"to":_vm.to,"type":_vm.type,"disabled":_vm.disabled || _vm.loading}},_vm.$listeners),[_c('div',{staticClass:"app-btn-container"},[(_vm.loading)?_c('div',{staticClass:"app-btn-loading-icon d-inline-block position-absolute w-100"},[_c('AppIcon',{staticClass:"app-btn-loading-icon-icon",attrs:{"name":"progress_bar_round"}})],1):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-center",class:{ 'app-btn-hidden': _vm.loading }},[(_vm.prependIcon)?_c('AppIcon',{staticClass:"mr-2",attrs:{"name":_vm.prependIcon}}):_vm._e(),_vm._t("default"),(_vm.appendIcon)?_c('AppIcon',{staticClass:"ml-2",attrs:{"name":_vm.appendIcon}}):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }