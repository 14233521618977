<template>
  <div
    class="app-icon"
    :class="{
      hoverable,
      'text-gray-dark-2': !iconComponent,
      'app-icon-reverse': reverse,
    }"
    :style="{ '--icon-color': colors[hoverColor] || hoverColor }"
  >
    <component
      :is="iconComponent || 'none'"
      :height="size"
      :width="size"
      v-on="$listeners"
    ></component>
  </div>
</template>

<script>
import ICONS from '@/shared/constants/IconConstants.js';
import colors from '@/scss/theme/_colors.scss';

export default {
  name: 'AppIcon',

  data() {
    return {
      colors,
    };
  },

  components: {
    ...ICONS,
  },

  props: {
    name: { type: String, default: 'home' },
    size: { type: [String, Number], default: 24 },
    hoverColor: { type: String, default: 'primary' },
    hoverable: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
  },

  computed: {
    iconComponent() {
      return ICONS[this.name] ? this.name : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
.app-icon {
  &.hoverable {
    cursor: pointer;
    transition: color 0.15s ease-in-out;
    // transition: opacity 0.15s ease-in-out;
    &:hover {
      color: var(--icon-color) !important;
    }
  }

  &-reverse {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
</style>
