<template>
  <div
    class="
      bg-gray-light-3
      h-100
      d-flex
      align-items-center
      justify-content-center
    "
  >
    <div
      class="
        p-5
        bg-primary
        text-white
        d-flex
        flex-column
        align-items-center
        rounded
      "
    >
      <AppIcon name="construction" :size="100" class="mb-4"></AppIcon>
      <h1 class="mb-5">PAGE NOT FOUND</h1>
      <AppBtn
        text
        color="black"
        prependIcon="arrow_left"
        @click="$router.back()"
      >
        <h3>BACK</h3>
      </AppBtn>
    </div>
  </div>
</template>

<script>
import AppBtn from '../../shared/elements/AppBtn.vue';
import AppIcon from '../../shared/elements/AppIcon.vue';
export default {
  components: { AppIcon, AppBtn },
};
</script>

<style lang="scss" scoped></style>
