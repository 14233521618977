import axios from 'axios';
import { URL_PREFIX } from './variables';

export const login = (...rest) => axios.post(`${URL_PREFIX}/login`, ...rest);
export const register = (...rest) =>
  axios.post(`${URL_PREFIX}/register`, ...rest);

export const forgotPassword = (email, ...rest) =>
  axios.post(`${URL_PREFIX}/password/request`, { email }, ...rest);
export const resetPassword = (...rest) =>
  axios.post(`${URL_PREFIX}/password/reset`, ...rest);
// export const resendVerificationLink = (...rest) =>
//   axios.post(`${URL_PREFIX}/verification/send`, ...rest);

// export const checkInviteCodeDetails = (...rest) =>
//   axios.post(`${URL_PREFIX}/contracts/check-invite-code`, ...rest);

export const self = (...rest) => axios.get(`${URL_PREFIX}/self`, ...rest);

export default {
  login,
  register,
  forgotPassword,
  resetPassword,
  self,
};
