import axios from 'axios';
import Cookies from 'vue-cookies';
import { USER_COOKIES } from '@/shared/constants/CookieConstants';
import store from '@/store';
import { isArray } from 'lodash';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
/**  REQUEST INTERCEPTOR  */
axios.interceptors.request.use(
  async (config) => {
    // enable loading
    store.dispatch('api/error', false);
    store.dispatch('api/loading', true);
    if (config.method.toLocaleLowerCase() === 'get') {
      store.dispatch('api/preventSuccessModal', true);
    }
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const jwt = Cookies.get(USER_COOKIES.auth_token);

    // add token if available and no custom Authorization added
    if (jwt !== null && !config.headers.Authorization)
      headers = {
        ...headers,
        Authorization: `Bearer ${jwt}`,
      };
    let pagination = {};
    if (config.method === 'get') {
      pagination = {
        per_page: config?.params?.per_page || 10,
      };
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        ...headers,
      },
      params: {
        ...config.params,
        ...pagination,
      },
    };
  },
  (error) => {
    store.dispatch('api/loading', false);
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // disable loading
    store.dispatch('api/loading', false);
    return response;
  },
  function (error) {
    let err = null;
    if (typeof error.response.data === 'object') {
      // if array of error
      if (
        isArray(error?.response?.data?.errors) &&
        error?.response?.data?.errors.length > 0
      ) {
        err = error?.response?.data?.errors[0];
      }
      // if error object
      else if (
        typeof error?.response?.data?.errors === 'object' &&
        Object.keys(error?.response?.data?.errors).length > 0
      ) {
        const errKeys = Object.keys(error.response.data.errors);
        const errorMessage = error?.response?.data?.errors[errKeys[0]];
        err = isArray(errorMessage) ? errorMessage[0] : errorMessage;
      }
      // if error just string
      else {
        err =
          error?.response?.data?.message || 'An error occured from the server!';
      }
    } else {
      err =
        error.response.status !== 500
          ? error?.response?.data
          : 'An error occured from the server!';
    }

    let skipErrors = [
      'No quotation eligible for auto-approve',
    ]

    if (skipErrors.includes(err) === false) {
      store.dispatch('api/showModalError', {
        header: err,
        description: '',
      });
    }

    if (error.response.data) {
      store.dispatch('api/error', err);
    } else {
      console.error(error);
    }

    store.dispatch('api/loading', false);
    return Promise.reject(error);
  }
);

export default axios;
