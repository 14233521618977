<template>
  <transition name="modal">
    <div class="app-modal modal fade show" v-if="innerVal">
      <div class="modal-mask" @click="innerVal = false"></div>
      <div
        class="modal-dialog modal-dialog-centered"
        :style="{ width: width, 'max-width': '95vw' }"
      >
        <!-- Skeletal slot scope -->
        <div
          class="modal-content"
          :style="skeletal ? `background-color: rgba(0, 0, 0, 0)` : ''"
        >
          <!-- Default slot scope -->
          <slot>
            <p>Pocketyard!</p>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';

export default {
  name: 'AppModal',

  mixins: [_appDefaultInput],

  props: {
    value: { type: [Boolean, String, Number, Array, Object], default: false },
    width: { type: String, default: null },
    skeletal: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: block;

  &-mask {
    position: absolute;
    z-index: 0;
    height: 100vh;
    width: 100vw;
    background-color: #0000004d;
  }

  &-dialog {
    margin-top: 0;
    position: relative;
    z-index: 999;

    &-centered {
      justify-content: center;
    }
  }
}

// transition classes
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.15s ease-in-out;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
