<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    class="app-btn btn text-decoration-none"
    :type="type"
    :disabled="disabled || loading"
    :class="[
      text
        ? `btn-text-${color}`
        : outline
        ? `bg-white btn-outline-${color}${
            disabled || loading ? '-disabled' : ''
          }`
        : `btn-${color}${disabled || loading ? '-disabled' : ''}`,
      {
        'btn-sm': sm,
        'btn-xl': xl,
      },
    ]"
    v-on="$listeners"
  >
    <div class="app-btn-container">
      <div
        v-if="loading"
        class="app-btn-loading-icon d-inline-block position-absolute w-100"
      >
        <AppIcon
          name="progress_bar_round"
          class="app-btn-loading-icon-icon"
        ></AppIcon>
      </div>
      <div
        class="d-flex align-items-center justify-content-center"
        :class="{ 'app-btn-hidden': loading }"
      >
        <AppIcon v-if="prependIcon" class="mr-2" :name="prependIcon"></AppIcon>
        <slot></slot>
        <AppIcon v-if="appendIcon" class="ml-2" :name="appendIcon"></AppIcon>
      </div>
    </div>
  </component>
</template>

<script>
import AppIcon from './AppIcon.vue';
export default {
  components: { AppIcon },
  name: 'AppBtn',

  props: {
    to: { type: [String, Object], default: null },
    sm: { type: Boolean, default: false },
    xl: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    color: { type: String, default: 'primary' },
    type: { type: String, default: 'button' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
  },
};
</script>
<style lang="scss" scoped>
.app-btn {
  &-container {
    position: relative;
    width: 100%;
    // height: 100%;
    // text-decoration: none;
  }
  &-hidden {
    opacity: 0;
  }
  &-loading-icon {
    position: absolute;
    top: -2%;
    left: 0;
    &-icon {
      // background: red;
      animation: loading 3.2s linear 0s infinite;
      padding: 1.51px;
      padding-top: 0 !important;
    }
  }
}
// Loading rotation animation
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
