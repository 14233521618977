<template>
  <div id="app">
    <!-- <LayoutProvider> -->
    <transition name="page">
      <router-view />
    </transition>
    <!-- </LayoutProvider> -->
    <!-- Easy to access modal via store -->
    <GlobalActionModal></GlobalActionModal>
  </div>
</template>

<script>
// import LayoutProvider from './components/Partial/Layouts/LayoutProvider.vue';
import GlobalActionModal from './components/Partial/Modals/GlobalActionModal.vue';
export default {
  metaInfo: {
    titleTemplate: (title) => {
      return title ? `pocketyard | ${title}` : 'pocketyard';
    },
    htmlAttrs: {
      lang: 'en',
      amp: true,
    },
  },

  name: 'App',
  components: { GlobalActionModal },
  methods: {
    initMarkerIO() {
      if (!document || !window) {
        return;
      }

      const markerIO = document.createElement('script');
      const markerIOScript = document.createTextNode(
        '!function(e,r,a){if(!e._Marker){e._Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);'
      );

      window.markerConfig = {
        destination: '61324d60b8d3db2407e17c8a',
        source: 'snippet',
      };

      markerIO.appendChild(markerIOScript);
      markerIO.setAttribute('async', true);
      markerIO.setAttribute('defer', true);

      document.head.appendChild(markerIO);
    },
  },
  mounted() {
    if (process.env.NODE_ENV !== 'production') {
      this.initMarkerIO();
    }
  },
};
</script>

<style lang="scss">
@import './scss/app';

.page {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.15s ease-out;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>

