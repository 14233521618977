<template>
  <ActionModal
    v-model="value"
    :state="modal.state || 'error'"
    :icon="displayIcon"
    :color="modal.state === 'error' ? 'danger' : modal.state"
    :header="modal.header"
    :description="modal.description"
    :btnText="modal.btnText"
    @close="modal.onClick"
  ></ActionModal>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionModal from './ActionModal.vue';
export default {
  components: { ActionModal },
  name: 'GlobalActionModal',

  data() {
    return {
      value: this.modalShow,
    };
  },

  watch: {
    value(val) {
      this.$store.dispatch('api/showModal', val);
    },
    modalShow(val) {
      this.value = val;
    },
  },

  computed: {
    ...mapGetters('api', [
      'loading',
      'modal',
      'modalShow',
      'modalState',
      'error',
    ]),
    displayIcon() {
      switch (this.modalState) {
        case 'error':
        case 'danger':
          return 'close_filled';
        case 'success':
        default:
          return 'checkmark_filled';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
