export default {
  namespaced: true,

  state: {
    title: '',
  },

  getters: {
    title: (state) => state.title,
  },

  mutations: {
    SET_TITLE: (state, title) => {
      state.title = title;
    },
  },

  actions: {
    setTitle(context, title) {
      context.commit('SET_TITLE', title);
    },
    clearTitle(context) {
      context.commit('SET_TITLE', '');
    },
  },
};
