import api from './api';
import auth from './auth';
import cart from './cart';
import page from './page';

export default {
  api,
  auth,
  cart,
  page,
};
