import store from '@/store';
import PersonaConstants from '@/shared/constants/PersonaConstants';

const routeGuard = (to, from, next) => {
  const token = store.getters['auth/token'];
  const user = store.getters['auth/user'];
  // auth page: proceed after checking
  if ((to.name === 'Login' || to.name === 'Register') && token && user) {
    next({ name: 'Dashboard' });
  } else if (to.meta.permissions) {
    const { personas, roles } = to.meta.permissions;
    if (!token) {
      return next({ name: 'Login' });
    } else if (
      user &&
      (!personas.includes(user.persona) || !isRoleAllowed(roles))
    ) {
      if (user.persona !== null) {
        return next({ name: 'Error404' });
      }
    }
    // if (token && user && !to.meta.layout) {
    //   to.meta.layout = getPersona().layout;
    // }
  }
  // public page: proceed normally
  next();
};

export default routeGuard;

/**  COMPLIMENTARY FUNCTIONS */

/**
 * @returns User Persona Constant Object
 */
export const getPersona = () => {
  const user = store.getters['auth/user'];

  if (!user || Object.keys(user).length === 0) {
    return PersonaConstants.default;
  } else if (user.persona !== null) {
    return PersonaConstants[user.persona];
  } else {
    return PersonaConstants.buyer;
  }
};

/**
 * @param {array} allowedRoles list of role string to check
 * @returns Boolean
 */
export const isRoleAllowed = (allowedRoles) => {
  if (!allowedRoles || allowedRoles.length === 0) {
    return true;
  }

  const user = store.getters['auth/user'];

  if (user && user.userGroups) {
    const { userGroups } = user;
    let i = 0;
    while (i < allowedRoles.length) {
      if (
        (typeof allowedRoles[i] === 'object' &&
          userGroups.includes(allowedRoles[i]?.name)) ||
        userGroups.includes(allowedRoles[i]) ||
        allowedRoles[i] === null
      ) {
        return true;
      }
      i++;
    }
  }

  return false;
};
