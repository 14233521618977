const modal = {
  show: false,
  state: '',
  header: '',
  description: '',
  btnText: '',
  onClick: () => {},
};

const state = {
  loading: false,
  hasError: false,
  modal,
  preventSuccessDefault: false,
};

const getters = {
  preventSuccess: ({ preventSuccessDefault }) => preventSuccessDefault,
  loading: ({ loading }) => loading,
  error: ({ hasError }) => hasError,
  modal: ({ modal }) => modal,
  modalShow: ({ modal }) => modal.show,
  modalState: ({ modal }) => modal.state,
  modalHeader: ({ modal }) => modal.header,
  modalDescription: ({ modal }) => modal.description,
  modalBtnText: ({ modal }) => modal.btnText,
  modalOnClick: ({ modal }) => modal.onClick,
};

const mutations = {
  PREVENT_SUCCESS_DEFAULT: (state, preventSuccessDefault = true) => {
    state.preventSuccessDefault = preventSuccessDefault;
  },
  IS_LOADING: (state, isLoading = true) => {
    state.loading = isLoading;
  },
  HAS_ERROR: (state, hasError = true) => {
    state.hasError = hasError;
  },
  SHOW_MODAL: (state, isShow = true) => {
    state.modal.show = isShow;
  },
  SET_MODAL_STATE: (state, modalState) => {
    state.modal.state = modalState;
  },
  SET_MODAL_HEADER: (state, header) => {
    state.modal.header = header;
  },
  SET_MODAL_DESCRIPTION: (state, description) => {
    state.modal.description = description;
  },
  SET_MODAL_BTN_TEXT: (state, text) => {
    state.modal.btnText = text;
  },
  SET_MODAL_CLICK: (state, onClick = () => {}) => {
    state.modal.onClick = onClick;
  },
  RESET_MODAL: (state) => {
    state.modal = modal;
  },
};

const actions = {
  loading: (context, isLoading = true) => {
    context.commit('IS_LOADING', isLoading);
  },

  error: (context, hasError = true) => {
    context.commit('HAS_ERROR', hasError);
  },
  showModal: (context, showModal = true) => {
    context.commit('SHOW_MODAL', showModal);
  },
  // Success Modal
  showModalSuccess: (context) => {
    if (!context.getters['modalHeader']) {
      context.commit('SET_MODAL_HEADER', 'SUBMITTED!');
    }
    if (!context.getters['modalDescription']) {
      context.commit(
        'SET_MODAL_DESCRIPTION',
        'Your request has been submitted! Thank you'
      );
    }
    if (!context.getters['modalBtnText']) {
      context.commit('SET_MODAL_BTN_TEXT', 'Close');
    }
    const oldOnClick = context.getters['modalOnClick'];
    const newOnClick = () => {
      context.dispatch('resetModal');
      oldOnClick();
    };
    context.commit('SET_MODAL_CLICK', newOnClick);

    context.commit('SET_MODAL_STATE', 'success');
    context.commit('SHOW_MODAL');
  },
  preventSuccessModal: (context, prevent = true) => {
    context.commit('PREVENT_SUCCESS_DEFAULT', prevent);
  },

  // Error Modal
  showModalError: (context, { header, description }) => {
    context.commit('SET_MODAL_STATE', 'error');
    context.commit('SET_MODAL_HEADER', header || 'UNEXPECTED ERROR');
    context.commit(
      'SET_MODAL_DESCRIPTION',
      typeof description === 'string'
        ? description
        : 'There was a problem with your request. Try again later.'
    );
    context.commit('SET_MODAL_BTN_TEXT', 'Close');
    context.commit('SHOW_MODAL');
  },
  setModal: (
    context,
    { state, header, description, btnText, onClick } = {}
  ) => {
    if (state) {
      context.commit('SET_MODAL_STATE', state);
    }
    if (header) {
      context.commit('SET_MODAL_HEADER', header);
    }
    if (description) {
      context.commit('SET_MODAL_DESCRIPTION', description);
    }
    if (btnText) {
      context.commit('SET_MODAL_BTN_TEXT', btnText);
    }
    if (onClick) {
      context.commit('SET_MODAL_CLICK', onClick);
    }
  },
  resetModal: (context) => {
    context.commit('RESET_MODAL');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
