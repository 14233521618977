export default {
  props: {
    value: {
      type: [String, Number, Array, Object, Boolean, Event],
      default: null,
    },
  },

  data() {
    return {
      innerVal: this.value,
    };
  },

  watch: {
    innerVal: {
      handler(newVal, oldVal) {
        if (
          typeof newVal === 'object' ||
          Array.isArray(newVal) ||
          newVal !== oldVal
        ) {
          this.$emit('input', newVal);
        }
      },
      deep: true,
    },
    value: {
      handler(newVal, oldVal) {
        if (newVal instanceof Event && newVal.data !== undefined) {
          this.$emit('input', newVal?.data);
        } else if (newVal !== oldVal || newVal !== this.innerVal) {
          this.innerVal = newVal;
        }
      },
      deep: true,
    },
  },
};
