export const STATUS = {
  REQUEST_PENDING: {
    value: 'pending',
    buyer_text: 'Pending Request',
    vendor_text: null,
    color: '',
  },
  REQUEST_APPROVED: {
    value: 'approved',
    buyer_text: 'Request Approved',
    vendor_text: 'Quotation Request',
    other_text: 'In Progress Quote Request',
  },
  QUOTE_SENT: {
    value: 'quote_sent',
    quote_value: 'submitted',
    buyer_text: 'Pending Approval',
    vendor_text: 'Quotation Sent',
  },
  QUOTE_APPROVED: {
    value: 'quote_approved',
    quote_value: 'approved',
    buyer_text: 'Quote Approved',
    vendor_text: 'Quote Approved',
  },
  QUOTE_REJECTED: {
    value: 'rejected',
    quote_value: 'rejected',
    buyer_text: 'Quote Declined',
    vendor_text: 'Quote Declined',
  },
  COMPLETED: {
    value: 'completed',
    quote_value: 'completed',
    buyer_text: 'Completed',
    vendor_text: 'Completed',
  },
  REQUEST_DECLINED: {
    value: 'rejected',
    buyer_text: 'Declined',
    vendor_text: null,
  },
  REQUEST_CANCELLED: {
    value: 'canceled',
    buyer_text: 'Cancelled',
    vendor_text: 'Cancelled',
  },
};

export const PRODUCT_TYPE = [
  {
    value: 'material',
    text: 'Materials & Supplies',
  },
  {
    value: 'rental',
    text: 'Equipment',
  },
  {
    value: 'services',
    text: 'Services',
  },
];

export const DELIVERY_TYPE = [
  {
    value: 'pick up',
    text: 'Pick Up',
  },
  {
    value: 'ship to address',
    text: 'Ship to Address',
  },
];

export const APPROVAL_PREFERENCE = [
  {
    value: 'manual',
    text: 'Manual Approval',
  },
  {
    value: 'lowest',
    text: 'Lowest Price',
  },
  {
    value: 'fastest',
    text: 'Fastest Delivery',
  },
  {
    value: 'auto',
    text: 'Auto-approve based on my previous purchase history with vendor',
  },
];

export default {
  STATUS,
  PRODUCT_TYPE,
  DELIVERY_TYPE,
  APPROVAL_PREFERENCE,
};
