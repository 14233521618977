<template>
  <AppModal v-model="innerVal" width="550px">
    <div
      class="p-4 d-flex flex-column align-items-center justify-content-center"
    >
      <slot>
        <!-- Content -->
        <slot name="content">
          <AppIcon
            :name="icon || stateIcon"
            size="70"
            :class="`text-${color || stateColor}`"
          ></AppIcon>
          <h5 class="mt-2">
            <slot name="title">{{ header }}</slot>
          </h5>
          <p class="mt-2">
            <slot name="description">{{ description }}</slot>
          </p>
        </slot>
        <!-- Action Buttons -->
        <slot name="action-btn">
          <!-- Confirm Action Buttons -->
          <div v-if="['confirm', 'delete'].includes(state)" class="mt-2 d-flex">
            <AppBtn
              outline
              color="secondary"
              class="d-block"
              @click="(innerVal = false), $emit('cancel')"
              :loading="loading"
            >
              {{ btnTextCancel }}
            </AppBtn>
            <AppBtn
              class="ml-2 d-block"
              :color="state === 'delete' ? 'danger' : 'primary'"
              :loading="loading"
              @click="(innerVal = persistConfirm), $emit('confirm')"
            >
              {{ btnTextConfirm }}
            </AppBtn>
          </div>
          <!-- Success/Error Action Buttons -->
          <div v-else class="mt-2">
            <AppBtn
              class="ml-auto mr-auto d-block"
              :loading="loading"
              @click="(innerVal = false), $emit('cancel')"
            >
              {{ btnText }}
            </AppBtn>
          </div>
        </slot>
      </slot>
    </div>
  </AppModal>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppModal from '@/shared/elements/AppModal.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';

export default {
  name: 'ActionModal',

  components: { AppModal, AppIcon, AppBtn },

  mixins: [_appDefaultInput],

  props: {
    state: {
      type: String,
      default: 'confirm',
      validator: (value) =>
        ['success', 'error', 'confirm', 'delete'].includes(value),
    },
    header: { type: String, default: 'Header' },
    description: { type: String, default: 'Text here something someting ' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    btnText: { type: String, default: 'Close' },
    btnTextCancel: { type: String, default: 'Cancel' },
    btnTextConfirm: { type: String, default: 'Confirm' },
    loading: { type: Boolean, defauilt: false },
    persistConfirm: { type: Boolean, default: false },
  },

  watch: {
    innerVal(val) {
      if (!val) {
        this.$emit('close');
      }
    },
  },

  computed: {
    stateColor() {
      switch (this.state) {
        case 'success':
          return 'success';
        case 'delete':
        case 'error':
          return 'danger';
        default:
        case 'confirm':
          return 'primary';
      }
    },
    stateIcon() {
      switch (this.state) {
        case 'success':
          return 'checkmark_filled';
        case 'error':
          return 'close_filled';
        default:
        case 'confirm':
          return 'help';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
