/**
 * Cookies names for user profile and authentication
 */
export const USER_COOKIES = {
  auth_token: 'auth_jwt',
  user_data: 'auth_user',
  AUTH_TOKEN: 'auth_jwt',
  USER_DATA: 'auth_user',
};

/**
 * Cookie names for adding product to cart/checkout (tbd)
 */
export const CART_COOKIES = {
  cart_items: 'cart_items',
  checkout_items: 'checkout_items',
  checkout_project: 'checkout_project',
  checkout_project_id: 'checkout_project_id',
};

export default {
  USER_COOKIES,
  CART_COOKIES,
};
