// to be used
import axios from 'axios';
import { URL_PREFIX } from './variables';

export const updateUser = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/user/${id}`, ...rest);
export const postUser = (...rest) => axios.post(`${URL_PREFIX}/user`, ...rest);
export const getUser = (id, ...rest) =>
  axios.get(`${URL_PREFIX}/user/${id}`, ...rest);
export const getUsers = (active_only = true, ...rest) =>
  axios.get(
    `${URL_PREFIX}/user${active_only ? '?active_only=1' : ''}`,
    ...rest
  );
export const getUserGroups = (...rest) =>
  axios.get(`${URL_PREFIX}/user/group`, ...rest);

export const registerUser = (...rest) => axios.post(`${URL_PREFIX}/register`, ...rest);


export default {
  updateUser,
  postUser,
  getUser,
  getUsers,
  getUserGroups,
  registerUser,
};
