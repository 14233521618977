import Cookies from 'vue-cookies';
import { USER_COOKIES } from '@/shared/constants/CookieConstants';
import { login, register, self } from '@/shared/api/Authentication';
import { getUserGroups } from '@/shared/api/Users';

const state = {
  access_token: Cookies.get(USER_COOKIES.auth_token),
  user: Cookies.get(USER_COOKIES.user_data),
};

const getters = {
  token: (state) => state.access_token,
  user: (state) => state.user,
};

const mutations = {
  SET_TOKEN: (state, { token, expiration }) => {
    state.access_token = token;
    Cookies.set(USER_COOKIES.auth_token, token, expiration);
  },
  SET_USER: (state, payload) => {
    state.user = payload;
    Cookies.set(USER_COOKIES.user_data, JSON.stringify(payload));
  },
  CLEAR_TOKEN(state) {
    Cookies.remove(USER_COOKIES.auth_token);
    state.token = null;
  },
  CLEAR_USER(state) {
    Cookies.remove(USER_COOKIES.user_data);
    state.user = null;
  },
};

const actions = {
  /**
   * UNDER CONSTRUCTION
   * store action that calls the event register API
   * and store token to the localStorage and vuex
   */
  register: (context, { data, onSuccess, onError, onDone } = {}) => {
    // register post request
    register(data)
      // register successs
      .then((response) => {
        context.commit('SET_TOKEN', response.data.data.token);
        context.commit('SET_USER', response.data.data.user);

        if (typeof onSuccess === 'function') onSuccess(response);
      })
      // register error
      .catch((error) => {
        console.error('login', error);
        if (typeof onError === 'function') onError(error);
      })
      // register done
      .then((response) => {
        if (typeof onDone === 'function') onDone(response);
      });
  },

  /**
   * store action that calls the event login API
   * and store token to the localStorage and vuex
   */
  login: (context, { data, onSuccess, onError, onDone, persistTill } = {}) => {
    // login post request
    login(data)
      // login success
      .then(async (response) => {
        const expireAt = persistTill || '';
        context.commit('SET_TOKEN', {
          token: response.data.token.accessToken,
          expiration: expireAt,
        });

        // Single Role from previous implementation
        let userGroup = [];
        if (response.data.user.user_group_id) {
          const userGroups = await getUserGroups();
          const hasUserGroup = userGroups.data.find(
            (userGroup) => userGroup.id === response.data.user.user_group_id
          ).name;
          if (hasUserGroup) {
            userGroup.push(hasUserGroup);
          }
        }
        // Multi role
        const userGroups = response.data.user.groups.map(
          (group) => group?.name
        );
        const user = {
          ...response.data.user,
          user_group: userGroup || 'buyer', // this will be discared later on
          userGroups: [...userGroup, ...userGroups], //this will be the new implementation
        };
        context.commit('SET_USER', user);
        if (typeof onSuccess === 'function') onSuccess(response);
      })
      // login error
      .catch((error) => {
        console.error('login', error);
        if (typeof onError === 'function') onError(error);
      })
      // login done
      .then((response) => {
        if (typeof onDone === 'function') onDone(response);
      });
  },

  /**
   * UNDER CONSTRUCTION
   * store action that calls the event logout API
   * and remove  token from the localStorage and vuex
   */
  logout(context, { onLogout }) {
    context.commit('CLEAR_TOKEN');
    context.commit('CLEAR_USER');
    onLogout();
  },

  refreshSelf(context, { onSuccess, onError, onDone } = {}) {
    // login post request
    self()
      // login success
      .then(async (response) => {
        // Single Role from previous implementation
        let userGroup = [];
        if (response.data.user_group_id) {
          const userGroups = await getUserGroups();
          const hasUserGroup = userGroups.data.find(
            (userGroup) => userGroup.id === response.data.user_group_id
          ).name;
          if (hasUserGroup) {
            userGroup.push(hasUserGroup);
          }
        }
        // Multi role
        const userGroups = response.data.groups.map(
          (group) => group?.name
        );
        const user = {
          ...response.data,
          user_group: userGroup || 'buyer', // this will be discared later on
          userGroups: [...userGroup, ...userGroups], //this will be the new implementation
        };
        context.commit('SET_USER', user);
        if (typeof onSuccess === 'function') onSuccess(response);
      })
      // login error
      .catch((error) => {
        console.error('Refresh Self: ', error);
        if (typeof onError === 'function') onError(error);
      })
      // login done
      .then((response) => {
        if (typeof onDone === 'function') onDone(response);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
