// sort alphabetically
import add from '@carbon/icons-vue/es/add/32';
import add_filled from '@carbon/icons-vue/es/add--filled/32';
import analytics from '@carbon/icons-vue/es/analytics/32';
import arrow_left from '@carbon/icons-vue/es/arrow--left/32';
import arrow_right from '@carbon/icons-vue/es/arrow--right/32';
import arrow_up_right from '@carbon/icons-vue/es/arrow--up-right/32';
import camera from '@carbon/icons-vue/es/camera/32';
import caret_down from '@carbon/icons-vue/es/caret--down/32';
import caret_right from '@carbon/icons-vue/es/caret--right/32';
import caret_up from '@carbon/icons-vue/es/caret--up/32';
import catalog from '@carbon/icons-vue/es/catalog/32';
import checkbox from '@carbon/icons-vue/es/checkbox/32';
import checkbox_checked from '@carbon/icons-vue/es/checkbox--checked--filled/32';
import checkmark from '@carbon/icons-vue/es/checkmark/32';
import checkmark_filled from '@carbon/icons-vue/es/checkmark--filled/32';
import chevron_left from '@carbon/icons-vue/es/chevron--left/32';
import chevron_right from '@carbon/icons-vue/es/chevron--right/32';
import collaborate from '@carbon/icons-vue/es/collaborate/32';
import construction from '@carbon/icons-vue/es/construction/32';
import close from '@carbon/icons-vue/es/close/32';
import close_filled from '@carbon/icons-vue/es/close--filled/32';
import currency_dollar from '@carbon/icons-vue/es/currency--dollar/32';
import document from '@carbon/icons-vue/es/document/32';
import document_attachment from '@carbon/icons-vue/es/document--attachment/32';
import document_download from '@carbon/icons-vue/es/document--download/32';
import dot_mark from '@carbon/icons-vue/es/dot-mark/32';
import edit from '@carbon/icons-vue/es/edit/32';
import email from '@carbon/icons-vue/es/email/32';
import favorite from '@carbon/icons-vue/es/favorite/32';
import group from '@carbon/icons-vue/es/group/32';
import hamburger_menu from '@carbon/icons-vue/es/menu/32';
import home from '@carbon/icons-vue/es/home/32';
import help from '@carbon/icons-vue/es/help--filled/32';
import image_view from '@carbon/icons-vue/es/image/32';
import logout from '@carbon/icons-vue/es/logout/32';
import locked from '@carbon/icons-vue/es/locked/32';
import none from '@carbon/icons-vue/es/border--none/32';
import notification from '@carbon/icons-vue/es/notification/32';
import notification_filled from '@carbon/icons-vue/es/notification--filled/32';
import overflow_menu_horizontal from '@carbon/icons-vue/es/overflow-menu--horizontal/32';
import play_filled from '@carbon/icons-vue/es/play--filled/32';
import progress_bar_round from '@carbon/icons-vue/es/circle-dash/32';
import radiobutton from '@carbon/icons-vue/es/radio-button/32';
import radiobutton_checked from '@carbon/icons-vue/es/radio-button--checked/32';
import copy from '@carbon/icons-vue/es/copy/32';
import search from '@carbon/icons-vue/es/search/32';
import send from '@carbon/icons-vue/es/send/32';
import settings from '@carbon/icons-vue/es/settings/32';
import settings_adjust from '@carbon/icons-vue/es/settings--adjust/32';
import shopping_catalog from '@carbon/icons-vue/es/shopping--catalog/32';
import shopping_cart from '@carbon/icons-vue/es/shopping--cart/32';
import sort_descending from '@carbon/icons-vue/es/sort--descending/32';
import tag from '@carbon/icons-vue/es/tag/32';
import trash_can from '@carbon/icons-vue/es/trash-can/32';
import user from '@carbon/icons-vue/es/user/32';
import user_avatar_filled from '@carbon/icons-vue/es/user--avatar--filled/32';
import unlocked from '@carbon/icons-vue/es/unlocked/32';
import visible from '@carbon/icons-vue/es/view/32';
import visible_off from '@carbon/icons-vue/es/view--off/32';
import warning from '@carbon/icons-vue/es/warning--filled/32';
import warning_alt from '@carbon/icons-vue/es/warning--alt--filled/32';
import card from '@carbon/icons-vue/es/purchase/32';


// sort alphabetically
const ICON_LIST = {
  add,
  add_filled,
  analytics,
  arrow_left,
  arrow_right,
  camera,
  caret_down,
  caret_right,
  caret_up,
  catalog,
  checkbox,
  checkbox_checked,
  checkmark,
  checkmark_filled,
  chevron_left,
  chevron_right,
  collaborate,
  construction,
  close,
  close_filled,
  currency_dollar,
  document,
  document_attachment,
  dot_mark,
  edit,
  ellipsis: overflow_menu_horizontal,
  email,
  favorite,
  group,
  hamburger_menu,
  home,
  help,
  image_view,
  locked,
  logout,
  none,
  notification,
  notification_filled,
  overflow_menu_horizontal,
  play_filled,
  progress_bar_round,
  radiobutton,
  radiobutton_checked,
  search,
  send,
  settings,
  settings_adjust,
  shopping_catalog,
  shopping_cart,
  sort_descending,
  tag,
  trash_can,
  user,
  unlocked,
  visible,
  visible_off,
  warning,
  warning_alt,
  copy,
  card,
  arrow_up_right,
  document_download,
  user_avatar_filled,
};

export default ICON_LIST;
