import Cookies from 'vue-cookies';
import { CART_COOKIES } from '@/shared/constants/CookieConstants';

const state = {
  cart: JSON.parse(Cookies.get(CART_COOKIES.cart_items) || '[]'),
  checkout: JSON.parse(Cookies.get(CART_COOKIES.checkout_items) || '[]'),
  checkout_project: Cookies.get(CART_COOKIES.checkout_project),
  checkout_project_id: Cookies.get(CART_COOKIES.checkout_project_id),
  // checkout: [
  //   {
  //     group: 0
  //     project_name: 'XYZ Test',
  //     items: [
  //       productObject,
  //       productObject,
  //       productObject,
  //     ]
  //   }
  // ]
  // projects: [],
};

const getters = {
  // projects: (state) => state.projects,
  cart: (state) => {
    return state.cart.map((cartItem) => {
      const itemWithThumb = cartItem.items.find(
        (item) => item?.thumbnail && item.thumbnail.length > 0
      );

      return {
        ...cartItem,
        thumbnail: itemWithThumb?.thumbnail || null,
      };
    });
  },
  active_cart: (state) => {
    return state.cart
      .filter((cartItem) => cartItem.items.length > 0)
      .map((cartItem) => {
        const itemWithThumb = cartItem.items.find(
          (item) => item?.thumbnail && item.thumbnail.length > 0
        );
        return {
          ...cartItem,
          thumbnail: itemWithThumb?.thumbnail || null,
        };
      });
  },
  checkout: (state) => state.checkout.filter((item) => item.project_id === parseInt(state.checkout_project_id)),
  checkout_project: (state) => state.checkout_project,
  checkout_project_id: (state) => state.checkout_project_id,
};

const mutations = {
  // PROJECT MUTATORS
  ADD_CART_PROJECT: (state, project_name) => {
    let existing = state.cart.find(
      (cart) => cart.project_name === project_name
    );
    let new_project_name = project_name;
    if (existing) {
      return;
    }
    // let counter = 1;
    // while (existing) {
    //   new_project_name = `${project_name} ${counter}`;
    //   existing = state.cart.find(
    //     (cart) => cart.project_name === new_project_name
    //   );
    //   counter++;
    // }

    const newProject = {
      group: null,
      project_name: new_project_name,
      items: [],
    };
    state.cart.push(newProject);
    Cookies.set(CART_COOKIES.cart_items, JSON.stringify(state.cart));
  },
  RENAME_CART_PROJECT: (state, { project_id, new_name }) => {
    const itemIndex = state.cart.findIndex(
      (item) => item.project_id === parseInt(project_id)
    );
    state.cart[itemIndex].project_name = new_name;
    Cookies.set(CART_COOKIES.cart_items, JSON.stringify(state.cart));
  },
  REMOVE_CART_PROJECT: (state, project_name) => {
    const itemIndex = state.cart.findIndex(
      (item) => item.project_name === project_name
    );
    state.cart.splice(itemIndex, 1);
    Cookies.set(CART_COOKIES.cart_items, JSON.stringify(state.cart));
  },
  // CART ITEM MUTATORS
  ADD_CART_ITEM: (state, { project, item }) => {
    const existingCartIndex = state.cart.findIndex(
      (projectItem) => projectItem.project_name === project.name
    );

    if (existingCartIndex >= 0) {
      const items = state.cart[existingCartIndex].items;
      const existingIndex = items.findIndex(
        (cartItem) => cartItem.id === item.id
      );
      if (existingIndex >= 0) {
        state.cart[existingCartIndex].items[existingIndex].quantity = parseInt(
          item.quantity
        );
      } else {
        state.cart[existingCartIndex].items.push(item);
      }

      state.cart[existingCartIndex].project_id = project.id;
      Cookies.set(CART_COOKIES.cart_items, JSON.stringify(state.cart));
    } else {
      console.error("Project doesn't exist yet");
    }
  },
  REMOVE_CART_ITEM: (state, { project_name, item }) => {
    const cartIndex = state.cart.findIndex(
      (projecItem) => projecItem.project_name === project_name
    );
    if (cartIndex < 0) {
      return;
    }
    const itemIndex = state.cart[cartIndex].items.findIndex(
      (cartItem) => cartItem.id === item.id
    );
    if (itemIndex >= 0) {
      state.cart[cartIndex].items.splice(itemIndex, 1);
      Cookies.set(CART_COOKIES.cart_items, JSON.stringify(state.cart), '5y');
    }
  },
  CLEAR_CART(state, project_name = null) {
    if (project_name) {
      const cartIndex = state.cart.findIndex(
        (projecItem) => projecItem.project_name === project_name
      );

      if (cartIndex >= 0) {
        state.cart[cartIndex].items = [];
        Cookies.set(CART_COOKIES.cart_items, JSON.stringify(state.cart), '5y');
      }
    } else {
      state.cart = [];
      Cookies.remove(CART_COOKIES.cart_items);
    }
  },
  // CHECKOUT MUTATORS
  ADD_CHECKOUT_ITEM: (state, item) => {
    const items = state.checkout;
    const existingIndex = items.findIndex(
      (cartItem) => cartItem.id === item.id
    );
    if (existingIndex >= 0) {
      const quantity = parseInt(state.checkout[existingIndex].quantity);
      state.checkout[existingIndex].quantity =
        quantity + parseInt(item.quantity);
    } else {
      state.checkout.push(item);
    }

    Cookies.set(
      CART_COOKIES.checkout_items,
      JSON.stringify(state.checkout),
      '5y'
    );
  },
  REMOVE_CHECKOUT_ITEM: (state, item) => {
    const itemIndex = state.checkout.findIndex(
      (checkoutItem) =>
        checkoutItem.product_id === item.product_id &&
        checkoutItem.product_variant_id === item.product_variant_id &&
        checkoutItem.product_variant_id === item.product_variant_id &&
        checkoutItem.quantity_id === item.quantity_id
    );
    state.checkout.splice(itemIndex, 1);
    Cookies.set(
      CART_COOKIES.checkout_items,
      JSON.stringify(state.checkout),
      '5y'
    );
  },
  CLEAR_CHECKOUT(state) {
    state.checkout = [];
    Cookies.remove(CART_COOKIES.checkout_items);
  },
  SET_CHECKOUT_PROJECT: (state, project) => {
    state.checkout_project = project.project_name;
    state.checkout_project_id = project.project_id;
    Cookies.set(CART_COOKIES.checkout_project, project.project_name, '5y');
    Cookies.set(CART_COOKIES.checkout_project_id, project.project_id, '5y');
  },
  CLEAR_CHECKOUT_PROJECT: (state) => {
    state.checkout_project = ' ';
    Cookies.remove(CART_COOKIES.checkout_project);
  },
  CLEAR_CHECKOUT_PROJECT_ID: (state) => {
    state.checkout_project_id = null;
    Cookies.remove(CART_COOKIES.checkout_project_id);
  },
};

const actions = {
  newProject(context, project_name) {
    context.commit('ADD_CART_PROJECT', project_name);
  },
  renameProject(context, { project_id, new_name }) {
    context.commit('RENAME_CART_PROJECT', { project_id, new_name });
  },
  removeProject(context, project_name) {
    context.commit('REMOVE_CART_PROJECT', project_name);
  },
  addToCart: (context, { project, item }) => {
    context.commit('ADD_CART_ITEM', { project, item });
  },
  removeToCart: (context, { project_name, item }) => {
    context.commit('REMOVE_CART_ITEM', { project_name, item });
  },
  clearCart: (context) => {
    context.commit('CLEAR_CART');
  },
  clearProjectCart: (context, project_name) => {
    if (project_name) {
      context.commit('CLEAR_CART', project_name);
    }
  },
  projectCheckout: (context, project) => {
    const projectCart = context.getters['cart'].find(
      (item) => item.project_id === project.project_id
    );
    context.commit('SET_CHECKOUT_PROJECT', project);
    context.commit('CLEAR_CHECKOUT');
    projectCart.items.forEach((item) => {
      context.commit('ADD_CHECKOUT_ITEM', item);
    });
  },
  addToCheckout: (context, payload) => {
    state.checkout_project_id = payload.project_id
    context.commit('ADD_CHECKOUT_ITEM', payload);
    context.commit('CLEAR_CHECKOUT_PROJECT');
  },
  removeToCheckout: (context, item) => {
    context.commit('REMOVE_CHECKOUT_ITEM', item);
  },
  clearCheckout: (context) => {
    context.commit('CLEAR_CHECKOUT');
    context.commit('CLEAR_CHECKOUT_PROJECT');
    context.commit('CLEAR_CHECKOUT_PROJECT_ID');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
